<template>
  <div class="p-grid p-fluid">
    <div class="p-col-12 p-md-12">
      <div class="card card-w-title">
        <h5>Compléter mon inscription</h5>
        <p>Renseigner chaque formulaire dans l'ordre établi</p>
        <TabMenu :model="nestedRouteItems" :readonly="false" />
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import http from '@/services/http.service';
import LoggedUserMixin from '@/mixins/logged-user';

export default {
  mixins: [LoggedUserMixin],
  data() {
    return {
      loggedUser: null,
      currentSubscription: null,
      nestedRouteItems: [
        {
          label: 'Informations personnelles >',
          to: '/menu',
        },
        {
          label: "Informations de l'entreprise >",
          to: '/menu/seat',
        },
        {
          label: "S'abonner",
          to: '/menu/payment',
          disabled: this.canActivatePayment(),
        },
      ],
    };
  },
  async mounted() {
    const token = localStorage.getItem('token');
    if (token) {
      const config = {
        headers: { Authorization: `Bearer ${this.token}` },
      };
      const res = await http.get('/users/getProfile', config);
      this.loggedUser = res.data;
      this.currentSubscription = this.getCurrentSubscription(this.loggedUser);
    }
  },
  methods: {
    canActivatePayment() {
      return this.isFillingPaymentForm(this.currentSubscription);
    },
    toggleMenu(event) {
      this.$refs.menu.toggle(event);
    },
    onContextRightClick(event) {
      this.$refs.contextMenu.show(event);
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep(.stepsdemo-content) {
  padding: 1em 0;
  p {
    font-weight: 400;
    display: inline-block;
    vertical-align: middle;
    font-size: 18px;
    margin: 0;
  }
  i {
    vertical-align: middle;
    font-size: 1.5em;
    margin: 0;
  }
}
.contextmenu-image {
  width: 100%;
}
</style>
